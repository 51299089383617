
import { UserModule } from '@/store/user';
import { Vue, Component } from 'vue-property-decorator';
import { getInfo } from '@/api/user';

@Component({
  name: 'JoinSuccess',
})
export default class extends Vue {
  mounted() {
    getInfo().then((res) => {
      this.nickName = res.data.nickName;
      this.homepageState = res.data.homepageState;
    });
    if (UserModule.userId) {
      this.userId = UserModule.userId;
    } else {
      this.$router.push({ name: 'Login' });
    }
  }

  private userId = '';

  private homepageState = false;

  private nickName = '';
}
